
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
}

body {
  background-color: #FFFFFF;
  font-family: "Visby-CF", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Visby-CF";
  src: url("../src/assets/fonts/visby-cf/Fontspring-DEMO-visbycf-light.otf");
  font-weight: 300;
}
@font-face {
  font-family: "Visby-CF";
  src: url("../src/assets/fonts/visby-cf/Fontspring-DEMO-visbycf-regular.otf");
  font-weight: 400;
}
@font-face {
  font-family: "Visby-CF";
  src: url("../src/assets/fonts/visby-cf/Fontspring-DEMO-visbycf-medium.otf");
  font-weight: 500;
}
@font-face {
  font-family: "Visby-CF";
  src: url("../src/assets/fonts/visby-cf/Fontspring-DEMO-visbycf-demibold.otf");
  font-weight: 600;
}
@font-face {
  font-family: "Visby-CF";
  src: url("../src/assets/fonts/visby-cf/Fontspring-DEMO-visbycf-bold.otf");
  font-weight: 700;
}
@font-face {
  font-family: "Visby-CF";
  src: url("../src/assets/fonts/visby-cf/Fontspring-DEMO-visbycf-extrabold.otf");
  font-weight: 800;
}

@font-face {
  font-family: "Clash Display";
  src: url("../src/assets/fonts/clash-display/ClashDisplay-Regular.otf");
  font-weight: 400;
}
@font-face {
  font-family: "Clash Display";
  src: url("../src/assets/fonts/clash-display/ClashDisplay-Medium.otf");
  font-weight: 500;
}
@font-face {
  font-family: "Clash Display";
  src: url("../src/assets/fonts/clash-display/ClashDisplay-Semibold.otf");
  font-weight: 600;
}
@font-face {
  font-family: "Clash Display";
  src: url("../src/assets/fonts/clash-display/ClashDisplay-Bold.otf");
  font-weight: 700;
}
.contact select {
-moz-appearance:none; /* Firefox */
-webkit-appearance:none; /* Safari and Chrome */
appearance:none;
background-image: url('./assets/icons/caret-down.svg');
background-repeat: no-repeat;
background-position-x: 3%;
background-position-y: 50%;
}

.input{
  @apply bg-[#ECEBEB] py-3 px-5 border border-inputBorder rounded w-full placeholder:text-black focus:outline-none;
}
.contact-select{
  @apply bg-[#ECEBEB] py-3 pl-12 pr-5 border border-inputBorder rounded w-full placeholder:text-black focus:outline-none;
}
.contact-input{
  @apply bg-white py-3 px-5 border border-inputBorder rounded w-full placeholder:text-black placeholder:text-sm focus:outline-none;

}

.contact-us-button{
  @apply border-2 border-darkTeal text-sm md:text-base lg:text-lg text-darkTeal font-bold uppercase py-3 lg:py-4 px-5 lg:px-8 rounded-[200px] 
  w-fit flex items-center;
}

.custom-header-gradient{
  background: linear-gradient(89.81deg, rgba(9, 48, 64, 0.92) 0.82%, rgba(9, 31, 64, 0) 68.38%);
}
.custom-header-gradient-sm{
  background: linear-gradient(180deg, rgba(9, 48, 64, 0.92) 0.82%, rgba(9, 31, 64, 0) 68.38%);
}